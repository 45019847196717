import React, {Fragment} from "react";

import "./TechStackStyles.scss"
import angularLogo from "assets/angular-logo.png";
import reactLogo from "assets/react-logo.png"
import shopifyLogo from "assets/shopify-logo.png"
import tailwindLogo from "assets/tailwind-logo.png"
import dotnetLogo from "assets/dotnet-logo.png"
import nodeLogo from "assets/node-logo.png"
import azureSqlLogo from "assets/azure-sql-logo.png"
import firebaseLogo from "assets/firebase-logo.png"
import mongodbLogo from "assets/mongodb-logo.png"
import azureLogo from "assets/azure-logo.png"
import awsLogo from "assets/aws-logo.png"
import googleCloudLogo from "assets/google-cloud-logo.png"
import Title from "components/Title/Title";

interface Card {
    title: string;
    technologies: Technology[];
    tags: string[];
}

interface Technology {
    name: string;
    logo: string;
}

function TechStack() {
    const cards: Card[] = [
        {
            title: "Front-end",
            technologies: [
                {
                    name: "Angular",
                    logo: angularLogo
                },
                {
                    name: "React",
                    logo: reactLogo
                },
                {
                    name: "Shopify",
                    logo: shopifyLogo
                },
                {
                    name: "Tailwind",
                    logo: tailwindLogo
                }
            ],
            tags: [
                "Angular",
                "React",
                "Shopify",
                "Tailwind"
            ]
        },
        {
            title: "Back-end",
            technologies: [
                {
                    name: ".NET",
                    logo: dotnetLogo
                },
                {
                    name: "Node.js",
                    logo: nodeLogo
                }
            ],
            tags: [
                ".NET",
                "Node.js"
            ]
        },
        {
            title: "Database",
            technologies: [
                {
                    name: "SQL",
                    logo: azureSqlLogo
                },
                {
                    name: "Firebase",
                    logo: firebaseLogo
                },
                {
                    name: "MongoDB",
                    logo: mongodbLogo
                }
            ],
            tags: [
                "SQL",
                "Firebase",
                "MongoDB"
            ]
        },
        {
            title: "Cloud",
            technologies: [
                {
                    name: "Azure",
                    logo: azureLogo
                },
                {
                    name: "AWS",
                    logo: awsLogo
                },
                {
                    name: "Google",
                    logo: googleCloudLogo
                }
            ],
            tags: [
                "Azure",
                "AWS",
                "Google"
            ]
        }
    ];

    return (
        <div className="techStackContainer">
            <Title title="Technologies stack"/>

            <section className="cardList">
                {
                    cards.map((card, index) => {
                        return (
                            <article className="cardContainer" key={index}>
                                <div className="cardContent">
                                    <header className="cardHeader">
                                        <h2>{card.title}</h2>
                                    </header>

                                    <div className="cardTech">
                                        {
                                            card.technologies.map((technology, index) => {
                                                return (
                                                    <div className="techContainer" key={index}>
                                                        <div className="techAvatar">
                                                            <img src={technology.logo} alt={technology.name}/>
                                                        </div>
                                                        <div className="techName">
                                                            {technology.name}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </article>
                        )
                    })
                }
            </section>
        </div>
    );
}

    export default TechStack;