import React from "react";
import {Typography} from "@mui/material";

import "./IntroductionStyles.scss";
import robotBoy from "assets/robotboy.png";

function Introduction() {
    return (
        <>
            <div className="introductionContainer">
                <div className="introSection">
                    <Typography variant="h1" component="h1" className="title">
                        {"Your digital allies"}
                    </Typography>

                    <Typography variant="h4" component="h4" className="subtitle">
                        {"Crafting fluid digital solutions with a splash of innovation. Unleash the potential of your business in the ever-evolving tech landscape. Dive in!"}
                    </Typography>
                </div>

                <div className="floatingImageContainer">
                    <img src={robotBoy} alt="floating" className="floatingImage"/>
                </div>

                <div className="clipPath"></div>
            </div>

            <div className="introBackground"></div>
        </>
    );
}

export default Introduction;