import React, {Fragment} from "react";
import {Grid, Hidden, Typography} from "@mui/material";

import "./TeamStyles.scss";
import group1 from "assets/group_1.png";
import group2 from "assets/group_2.png";
import group1sm from "assets/group_1_sm.png";
import group2sm from "assets/group_2_sm.png";
import Title from "components/Title/Title";

function Team() {
    return (
        <Fragment>
            <Title title="Our Team"/>

            <Grid container alignItems="center" className="teamContainer">
                <Hidden mdDown>
                    <Grid item xs={12} md={3} className="leftColumn">
                        <img src={group1} alt="group of people"/>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid item xs={12} md={6} className="leftColumn">
                        <img src={group1sm} alt="group of people sm"/>
                    </Grid>
                </Hidden>

                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h4"
                        component="h4"
                        align="center"
                        className="midColumn preLine"
                    >
                        {
                            `At Liquid Labs, we are a team of young, passionate professionals driven by our love for technology and business.
                        

                            Our mission is to provide innovative, custom-made solutions for your digital needs, ensuring that your business stands out in the competitive online market`
                        }
                    </Typography>
                </Grid>

                <Hidden mdDown>
                    <Grid item xs={12} md={3} className="rightColumn">
                        <img src={group2} alt="group of people"/>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid item xs={12} md={3} className="rightColumn">
                        <img src={group2sm} alt="group of people sm"/>
                    </Grid>
                </Hidden>
            </Grid>
        </Fragment>
    );
}

export default Team;