const reviews = [
  {
    id: 1,
    name: "Sarah Thompson",
    job: "Entrepreneur & Small Business Owner",
    image:
      "https://images.pexels.com/photos/15835264/pexels-photo-15835264.jpeg?auto=compress&cs=tinysrgb&w=1600",
    text: "Working with Liquid Labs was a game-changer for my online boutique. Their team was incredibly attentive to my needs and crafted a stunning, responsive Shopify store that has significantly boosted my sales. I couldn't be happier with the results and would highly recommend Liquid Labs to anyone looking for a seamless e-commerce experience!",
  },
  {
    id: 2,
    name: "Michael Richards",
    job: "CEO of a Tech Startup",
    image:
      "https://images.pexels.com/photos/1438081/pexels-photo-1438081.jpeg?auto=compress&cs=tinysrgb&w=1600",
    text: "As a tech startup, we needed a reliable partner to develop a complex mobile application that could handle heavy user traffic and provide an excellent user experience. Liquid Labs exceeded our expectations at every turn. Their expertise, professionalism, and attention to detail have made all the difference. Our app has been a huge success, and we owe it to the exceptional work of the Liquid Labs team.",
  },
  {
    id: 3,
    name: "Emily Martinez",
    job: "Non-Profit Organization Director",
    image:
      "https://images.pexels.com/photos/15423154/pexels-photo-15423154.jpeg?auto=compress&cs=tinysrgb&w=1600",
    text: "Our non-profit organization was in desperate need of a website overhaul to better engage our supporters and showcase our mission. Liquid Labs was an absolute pleasure to work with. They took the time to understand our goals and created a beautifully designed, user-friendly website that has greatly increased our online presence. We couldn't be more grateful for their dedication and hard work.",
  },
  {
    id: 4,
    name: "Alex Peterson",
    job: "Digital Marketing Manager of a Growing Agency",
    image:
      "https://images.pexels.com/photos/1036627/pexels-photo-1036627.jpeg?auto=compress&cs=tinysrgb&w=1600",
    text: "In the competitive world of digital marketing, having a cutting-edge web application that helps us manage our clients' projects and campaigns is essential. Liquid Labs was the perfect choice for developing our custom solution. Their technical prowess, creativity, and collaborative approach resulted in a top-notch app that streamlined our processes and boosted our productivity. I wholeheartedly recommend Liquid Labs for any digital project you have in mind!",
  },
];

export default reviews;
