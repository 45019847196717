import React, {Fragment} from "react";
import {Typography} from "@mui/material";

import "./TitleStyles.scss";

interface TitleProps {
    title: string;
}

function Title(props: TitleProps) {
    return (
        <Fragment>
            <Typography variant="h2" component="h2" className="titleHeading">
                {props.title}
            </Typography>

            <div className="underline"></div>
        </Fragment>
    );
}

export default Title;