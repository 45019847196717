import React, {MouseEvent, RefObject} from "react";
import {
    Box,
    AppBar,
    Toolbar,
    Button,
} from '@mui/material';

import './NavbarStyles.scss';
import logo from "assets/logoname.png";

interface NavbarProps {
    contactUsRef: RefObject<HTMLDivElement>;
}

const Navbar = (props: NavbarProps) => {
    const onLogoClickHandler = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const onContactUsClickHandler = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        props.contactUsRef.current?.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <AppBar position="sticky" className="appBar">
            <Toolbar className="toolbar">
                <Box component="span" className="logo">
                    <img src={logo} alt="Logo" onClick={onLogoClickHandler}/>
                </Box>

                <div className="menuItems">
                    <Button component="span" color="primary" className="menuButton" onClick={onContactUsClickHandler}>
                        {'Get a Quote'}
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
