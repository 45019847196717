import {createTheme} from "@mui/material";

export const THEME = createTheme({
    palette: {
        primary: {
            main: "#6634AD"
        }
    },
    typography: {
        fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif",
        h1: {
            fontWeight: "bold"
        },
        h2: {
            fontWeight: "bold"
        },
        h3: {
            fontWeight: "bold"
        },
        h4: {
            fontWeight: "bold"
        },
        h5: {
            fontWeight: "bold"
        },
        h6: {
            fontWeight: "bold"
        }
    },
});