import React, {useState} from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import "./ReviewStyles.scss";
import people from "./data";
import {Typography} from "@mui/material";

const Review = () => {
    const [index, setIndex] = useState(0);
    const {id, name, job, image, text} = people[index];

    const checkNumber = (number: number) => {
        if (number > people.length - 1) {
            return 0;
        }
        if (number < 0) {
            return people.length - 1;
        }
        return number;
    };

    const nextPerson = () => {
        setIndex((index) => {
            let newIndex = index + 1;
            return checkNumber(newIndex);
        });
    };

    const prevPerson = () => {
        setIndex((index) => {
            let newIndex = index - 1;
            return checkNumber(newIndex);
        });
    };

    return (
        <div className="reviewContainer">
            <p className="info">{text}</p>

            <div className="imgContainer">
                <img src={image} alt={name} className="personImg"/>
                <span className="quoteIcon">
                    <FormatQuoteIcon/>
                </span>
            </div>

            <h4 className="author">{name}</h4>

            <p className="job">{job}</p>

            <div className="buttonsContainer">
                <button className="prevBtn" color="primary" onClick={prevPerson}>
                    <ChevronLeftIcon/>
                </button>
                <button className="nextBtn" color="primary" onClick={nextPerson}>
                    <ChevronRightIcon/>
                </button>
            </div>

            <div className="reviewContainerWrapper"></div>
        </div>

    );
};

export default Review;
