import React, {forwardRef, useState} from 'react';
import {TextField, Box, Button} from '@mui/material';
import emailjs from 'emailjs-com';

import "./ContactFormStyle.scss"
import Title from "components/Title/Title";

const ContactForm = forwardRef((props, ref) => {
    interface FormData {
        name: string,
        email: string,
        message: string
    }

    const [formData, setFormData] = useState<FormData>({name: '', email: '', message: ''});

    const handleChange = (e: any) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormData({name: '', email: '', message: ''});

        emailjs.sendForm('service_ibls4wf', 'template_xjzqdn8', e.target, 'XdQO9kLLpj2I_JXXA')
            .then(function () {
                console.log('SUCCESS!');
            }, function (error) {
                console.log('FAILED...', error);
            });
    };

    const isFormValid = (formData: FormData) => {
        return formData.name !== "" && formData.email !== "" && formData.message !== "";
    }

    return (
        <Box component="form" ref={ref} onSubmit={handleSubmit} className="contactForm">
            <Title title="Contact Us"/>

            <div className="container">
                <TextField
                    required
                    fullWidth
                    name="name"
                    label="Name"
                    value={formData.name}
                    onChange={handleChange}
                    variant="filled"
                    className="input"
                />

                <TextField
                    required
                    fullWidth
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    variant="filled"
                    className="input"
                />

                <TextField
                    required
                    fullWidth
                    name="message"
                    label="Message"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    variant="filled"
                    className="input"
                />

                <div className="sendMessageButton">
                    <Button type="submit" variant="contained" color="primary" disabled={!isFormValid(formData)}>
                        Send Message
                    </Button>
                </div>
            </div>
        </Box>
    );
});

export default ContactForm;
