import React from 'react';
import {Container, Typography, IconButton, Box, Grid} from "@mui/material";
import FacebookLogo from 'assets/facebook.png';
import InstagramLogo from 'assets/instagram.png';
import LinkedInLogo from 'assets/linkedin.png';
import TwitterLogo from 'assets/twitter.png';

import './FooterStyles.scss';

const Footer = () => {
    return (
        <div className="footer">
            <Box className="container">
                <Container maxWidth={false} className="socialMediaLinks">
                    <IconButton href="https://www.facebook.com/profile.php?id=100092268531279" target="_blank"
                                className="socialMediaButton">
                        <img src={FacebookLogo}/>
                    </IconButton>
                    <IconButton href="https://www.instagram.com/liquidlabsoft/" target="_blank"
                                className="socialMediaButton">
                        <img src={InstagramLogo}/>
                    </IconButton>
                    <IconButton href="https://www.linkedin.com/company/liquidlabs-ro" target="_blank"
                                className="socialMediaButton">
                        <img src={LinkedInLogo}/>
                    </IconButton>
                    <IconButton href="https://twitter.com/liquidlabsoft" target="_blank" className="socialMediaButton">
                        <img src={TwitterLogo}/>
                    </IconButton>
                </Container>

                <Container maxWidth={false} className="copyright">
                    <Typography variant="body2" align="center">
                        {`© ${new Date().getFullYear()} All rights reserved `}
                    </Typography>
                </Container>
            </Box>
        </div>
    );
};

export default Footer;
