import React from "react"
import {Container} from "@mui/system"
import {Grid, Typography} from "@mui/material"

import "./ServicesStyles.scss"
import Title from "components/Title/Title";
import shopify from "assets/shopify.png"
import web from "assets/web.png"
import mobile from "assets/mobile.png"
import custom from "assets/custom.png"

function Services() {
    return (
        <Container maxWidth={false} className="servicesContainer">
            <Title title="Our Services"/>

            <Grid container alignItems="center" className="row">
                <Grid className="content" item xs={12} md={6}>
                    <img src={web} alt="web" className="serviceImage"/>
                </Grid>

                <Grid className="content" item xs={12} md={6}>
                    <Typography component="h5">
                        {
                            " Whether you need a simple informational website or a complex web application, our team can design and develop the perfect solution. We work closely with you to understand your goals and deliver a website that meets your business needs."
                        }
                    </Typography>
                </Grid>
            </Grid>

            <Grid container alignItems="center" className="row">
                <Grid className="content order-lg-2" item xs={12} md={6}>
                    <img src={shopify} alt="shopify" className="serviceImage"/>
                </Grid>

                <Grid className="content" item xs={12} md={6}>
                    <Typography component="h5" color="white">
                        {
                            " We understand the importance of having a powerful, visually appealing, and user-friendly online store. At Liquid Labs, our team of Shopify experts can create a customized, tailor-made e-commerce store that not only meets your business requirements but also enhances your online presence."
                        }
                    </Typography>
                </Grid>

                <div className="contentBackgroundLeft"></div>
            </Grid>

            <Grid container alignItems="center" className="row">
                <Grid className="content" item xs={12} md={6}>
                    <img src={custom} alt="custom" className="serviceImage"/>
                </Grid>

                <Grid className="content" item xs={12} md={6}>
                    <Typography component="h5">
                        {
                            " At Liquid Labs, we believe that every client is unique and so are their requirements. Our team works tirelessly to develop custom solutions that not only meet your specific needs but also exceed your expectations."
                        }
                    </Typography>
                </Grid>
            </Grid>

            <Grid container alignItems="center" className="row">
                <Grid className="content order-lg-2" item xs={12} md={6}>
                    <img src={mobile} alt="app" className="serviceImage"/>
                </Grid>

                <Grid className="content" item xs={12} md={6}>
                    <Typography component="h5" color="white">
                        {
                            " Our team is proficient in developing high-quality mobile and web applications that cater to your business requirements. We have extensive experience in working with various platforms and can create custom apps that offer seamless user experiences and improve business operations."
                        }
                    </Typography>
                </Grid>

                <div className="contentBackgroundRight"></div>
            </Grid>
        </Container>
    )
}

export default Services