import React, {useRef} from "react";
import {
    Container,
    Divider,
    ThemeProvider,
} from "@mui/material";

import "./App.scss";
import {THEME} from "./theme";
import Navbar from "components/Navbar/Navbar";
import Introduction from "components/Introduction/Introduction";
import Team from "components/Team/Team";
import TechStack from "components/TechStack/TechStack";
import Review from "components/Review/Review";
import Footer from "components/Footer/Footer";
import Services from "components/Services/Services";
import ContactForm from "components/ContactUs/ContactForm";


function App() {
    const contactUsRef = useRef(null);

    return (
        <ThemeProvider theme={THEME}>
            <Navbar contactUsRef={contactUsRef}/>

            <Container maxWidth="xl" className="bodyContainer">
                <Introduction/>

                <Divider className="divider"/>

                <Team/>

                <Divider className="divider"/>

                <Services/>

                <Divider className="divider"/>

                <TechStack/>

                <Divider className="divider"/>

                <Review/>

                <Divider className="divider"/>

                <ContactForm ref={contactUsRef}/>
            </Container>

            <Footer/>
        </ThemeProvider>
    );
}

export default App;
